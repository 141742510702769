import React from 'react'
import { LandingLogo } from '../../../Assets/Svgs'
import { useNavigate } from 'react-router-dom'

const StickyHeader = () => {

    const navigate = useNavigate()

    return (
        <div className='d-flex align-items-center justify-content-between sticky-header'>
            <img src={LandingLogo} alt="" className='cursor-pointer' onClick={() => navigate('/')} />
            <div className='d-flex align-items-center justify-content-between right-navbar'>
                <h1 onClick={() => window.open('https://www.centiblick.com/add-on-features', '_blank')}>Add-On Features</h1>
                <h1 onClick={() => window.open("https://centiblickconnect.com/pricing", '_blank')}>Plans & Pricing</h1>
                <div className='d-flex gap-3'>
                    <button className='save-theme-btn shadow-none'>Support</button>
                    <button className='save-theme-btn shadow-none'>Provider Login</button>
                </div>
            </div>
        </div>
    )
}

export default StickyHeader