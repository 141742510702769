import { Amplify, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getLocalStorage, setLocalStorage } from '../../../Components/Provider/Utils/AuthHandlers';
import { fetchProviderProfileData } from '../../../Store/Thunk/getProfileData/providerProfileThunk';
import { setProviderplan } from '../../../Store/Slicers/commonSlicer';
import { useDispatch } from 'react-redux';
import ForgotPassword from '../../../Components/Common/ForgotPassword';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './WaitingScreen.scss';
import { EyeClose, EyeOpen, LandingLogo, LoginPopupBG, Padlock } from '../../../Assets/Svgs';
import { ProviderAwsConfig } from '../../../awsConfig';
import { ResetPasswordProfile } from '../../../Store/Thunk/ResetPassword/resetPasswordThunk';
import { ToastMessage } from '../../../Components/Common/ToastMessage';

const ProviderLoginFlow = () => {
  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: ProviderAwsConfig.REGION,
        userPoolId: ProviderAwsConfig.USER_POOL_ID,
        userPoolWebClientId: ProviderAwsConfig.USER_POOL_CLIENT_ID,
      },
    });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authToken = getLocalStorage("authToken");
  const [showModal, setShowModal] = useState<boolean>(true);
  const [forgotModal, setForgotModal] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    toast.loading("Please Wait");
    dispatch(setProviderplan("clinic"));
    try {
      const res = await Auth.signIn(values.email, values.password, { profile: 'provider' });
      toast.dismiss();
      const authToken = res?.signInUserSession?.idToken?.jwtToken;
      const userId = res.signInUserSession?.accessToken?.payload?.sub;
      if (authToken) {
        setLocalStorage("userToken", authToken);
        setLocalStorage('userData', JSON.stringify(res.signInUserSession.accessToken.payload));
        setLocalStorage('userEmail', res.attributes.email);
        dispatch(fetchProviderProfileData({ userId, authToken }));
        navigate('/provider-appointments');
      }
    } catch (error) {
      toast.dismiss();
      ToastMessage("error","Something went wrong. Please try again later.");
    }
  };

  const handleForgot = () => {
    setForgotModal(true);
  };

  const handlePassword = async (email: string) => {
    setLoader(true);
    toast.loading("Please wait...");
    let response: any = await dispatch(ResetPasswordProfile({ email }));
    if (response?.payload?.message === "password reset successful.") {
      ToastMessage("success","Email sent successfully");
    } else {
      ToastMessage("error","Something went wrong. Please try again later.");
    }
  };

  const closeModal = () => {
    setForgotModal(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (e: React.KeyboardEvent, submitFunction: () => void) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submission
      submitFunction(); // Call the submit function
    }
  };

  return (
    <div>
      <div className="background-container">
        <img src={LoginPopupBG} alt="" />
      </div>
      <div className={`modal fade ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }}>
        <div className={`modal-dialog modal-dialog-scrollable modal-dialog-centered justify-content-center modal-lg`}>
          <div className="modal-content bg-transparent p-0 shadow-none border-0 overflow-visible" style={{ maxWidth: "628px", backgroundColor: '#ffffff66' }}>
            <div className="modal-body p-0">
              <div className="waiting-screen new-login-popup" style={{ background: 'transparent' }}>
                {forgotModal ? (
                  <div className="waiting-login">
                    <h1 className="waiting-header justify-content-center align-items-center flex-row gap-4">Let’s setup a new password! <img src={Padlock} alt="" /></h1>
                    {/* <p className="desc">Let’s setup a new password</p> */}
                    <div className="signin-div d-flex w-75 align-items-center flex-column gap-4">
                      <div className="waiting-signin w-100 d-flex flex-column gap-4">
                        <Formik
                          initialValues={{ email: '' }}
                          validationSchema={Yup.object({
                            email: Yup.string().email('Invalid email address').required('Email Required'),
                          })}
                          onSubmit={({ email }) => handlePassword(email)}
                        >
                          {({ handleSubmit, errors, touched }) => (
                            <Form className="login-form d-flex flex-column" onSubmit={handleSubmit}>
                              <div>
                                <Field
                                  name="email"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Email"
                                  autoComplete='new-email'
                                  onKeyDown={(e: any) => handleKeyDown(e, handleSubmit)}
                                />
                                <ErrorMessage component='div' name='email' className='text-danger' />
                              </div>
                              <button
                                type="submit"
                                className="yes-theme-btn border-0"
                                style={{
                                  padding: "16px 24px",
                                  borderRadius: "6px",
                                }}
                                disabled={loader}
                              >
                                Continue
                              </button>
                            </Form>
                          )}
                        </Formik>
                      </div>
                      <div className={`text-center login-logo`}>
                        <img src={LandingLogo} alt="" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="waiting-login">
                    <h1 className="waiting-header">{window.innerWidth > 767 ? "Welcome Back!" : "Welcome!"}</h1>
                    <p className="desc">{window.innerWidth > 767 ? "Sign in to start seeing your patients" : "Sign in"}</p>
                    <div className={`signin-div d-flex align-items-center flex-column gap-4 ${window.innerWidth > 767 ? 'w-75' : "w-100"}`}>
                      <div className="waiting-signin w-100 d-flex flex-column gap-4">
                        <h1 className={`signin-header ${window.innerWidth > 767 ? '' : "d-none"}`}>Sign In</h1>
                        <Formik
                          initialValues={{ email: '', password: '' }}
                          validationSchema={Yup.object({
                            email: Yup.string().email('Invalid email address').required('Eamil is Required'),
                            password: Yup.string().required('Password is Required'),
                          })}
                          onSubmit={handleSubmit}
                        >
                          {({ handleSubmit, errors, touched }) => (
                            <Form className="login-form d-flex flex-column">
                              <div>
                                <Field
                                  name="email"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Email Address"
                                  autoComplete='new-email'
                                  onKeyDown={(e: any) => handleKeyDown(e, handleSubmit)}
                                />
                                <ErrorMessage component='div' name='email' className='text-danger' />
                              </div>
                              <div>
                                <div className='position-relative'>
                                  <Field
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Password"
                                    autoComplete='new-password'
                                    onKeyDown={(e: any) => handleKeyDown(e, handleSubmit)}
                                  />
                                  <img
                                    src={showPassword ? EyeOpen : EyeClose}
                                    className="position-absolute cursor-pointer"
                                    style={{ right: "15px", bottom: "20px" }}
                                    onClick={handleTogglePasswordVisibility}
                                    alt=""
                                  />
                                </div>
                                <ErrorMessage component='div' name='password' className='text-danger' />
                              </div>
                              <div className="remember d-flex align-items-center">
                                <input type="checkbox" />
                                <span>Remember me on this computer</span>
                              </div>
                              <button
                                type="submit"
                                className="yes-theme-btn border-0"
                                style={{
                                  padding: "16px 24px",
                                  borderRadius: "6px",
                                }}
                              >
                                Sign In
                              </button>
                              <p onClick={handleForgot} className="forgotpassword-title">Forgot Password</p>
                            </Form>
                          )}
                        </Formik>
                      </div>
                      <div className='signUp-tag'>
                        Don’t have a Telehealth account <span onClick={() => navigate('/pricing')}>Signup</span> today
                      </div>
                      <div className={`text-center login-logo`}>
                        <img src={LandingLogo} alt="" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProviderLoginFlow;
