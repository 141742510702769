import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface meetingMessages {
  text: string;
  fromUser: boolean;
  timestamp: string;
}
export interface AppState {
  themeColor: string;
  language: string;
  detailsPageTitle: string;
  plan: string;
  providerPlan: string;
  adminPlan: string;
  isUserSignIn: boolean;
  seletedId: string;
  socketProviderData: any;
  providerHome: boolean;
  ccpInitialize: boolean;
  chatConnected: boolean;
  chatMessages: {
    [userId: string]: meetingMessages[];
  };
  unreadMessages: {
    [userId: string]: number;
  };
  callType: string,
  callStatus: string,
  room_id: string,
  meeting_id: string,
  slider: boolean,
  fullScreen: boolean,
  startVideoCall: boolean,
  startChat: boolean,
  allNotes: string[],
  waitingRoom: any[],
  selectedAccessRoomData: any,
  webSocket: WebSocket | null,
}

const currunt_url = new URL(window.location.href)
const hostname = currunt_url.hostname
let plans;
if (hostname === 'provider.centiblickconnect.com') {
  plans = 'Individual'
} else if (hostname === 'clinic.centiblickconnect.com') {
  plans = 'clinic'
} else {
  plans = 'clinic'
}

const initialState: AppState = {
  themeColor: "blue",
  language: "en",
  detailsPageTitle: "",
  plan: plans,
  providerPlan: plans,
  adminPlan: plans,
  isUserSignIn: false,
  seletedId: "",
  socketProviderData: [],
  providerHome: false,
  ccpInitialize: false,
  chatConnected: false,
  chatMessages: {},
  unreadMessages: {},
  callType: "",
  callStatus: "",
  room_id: '',
  meeting_id: '',
  slider: true,
  fullScreen: false,
  startVideoCall: false,
  startChat: false,
  allNotes: [],
  waitingRoom: [],
  selectedAccessRoomData: null,
  webSocket:null
};


export const commonSlicer = createSlice({
  name: "app",
  initialState,
  reducers: {
    reset: () => initialState,
    getTheme: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.themeColor = action.payload;
    },
    setLanguage: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.language = action.payload;
    },
    setDetailsPageTitle: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.detailsPageTitle = action.payload;
    },
    setPlan: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.plan = action.payload;
    },
    setProviderplan: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.providerPlan = action.payload;
    },
    setAdminplan: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.adminPlan = action.payload;
    },
    setIsUserSignIn: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.isUserSignIn = action.payload;
    },
    setSeletedId: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.seletedId = action.payload;
    },
    setSocketProviderData: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.socketProviderData = action.payload;
    },
    setIsProviderHome: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.providerHome = action.payload;
    },
    setCcpInitialize: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.ccpInitialize = action.payload;
    },
    setChatConnected: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.chatConnected = action.payload;
    },
    setChatMessages: (
      state: Draft<typeof initialState>,
      action: PayloadAction<{ [userId: string]: meetingMessages }>
    ) => {
      const messagesByUser = action.payload;

      // Use Object.entries to handle the messages without an explicit loop
      Object.entries(messagesByUser).forEach(([userId, message]) => {
        // Initialize the array for this user if it doesn't exist
        state.chatMessages[userId] = state.chatMessages[userId] || [];

        // Push the new message to the existing array
        state.chatMessages[userId].push(message);
        if (!message.fromUser) {
          state.unreadMessages[userId] = (state.unreadMessages[userId] || 0) + 1;
        }
      });
    },
    resetUnreadCount: (
      state: Draft<AppState>,
      action: PayloadAction<string> // Accept userId to reset the count
    ) => {
      const userId = action.payload;
      state.unreadMessages[userId] = 0; // Reset unread count for the specific user
    },
    setCallType: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.callType = action.payload;
    },
    setCallStatus: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.callStatus = action.payload;
    },
    setRoomId: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.room_id = action.payload;
    },
    setMeetingId: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.meeting_id = action.payload;
    },
    setSlider: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.slider = action.payload;
    },
    setFullScreen: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.fullScreen = action.payload;
    },
    setStartVideoCall: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.startVideoCall = action.payload;
    },
    setStartChat: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.startChat = action.payload;
    },
    setAllNotes: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.allNotes = action.payload;
    },
    setWaitingRoom: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.waitingRoom = action.payload;
    },
    setSelectedAccessRoomData: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.selectedAccessRoomData = action.payload;
    },
    setWebSocket: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.webSocket = action.payload;
    },
  },
});

export const { getTheme, setLanguage, setDetailsPageTitle, setPlan, setProviderplan, setAdminplan, setIsUserSignIn, reset, setSeletedId, setSocketProviderData, setIsProviderHome, setCcpInitialize, setChatConnected, setChatMessages, setCallType, setCallStatus, setRoomId, setSlider, setFullScreen, setStartVideoCall, setMeetingId, setStartChat, setAllNotes, setWaitingRoom, resetUnreadCount, setSelectedAccessRoomData, setWebSocket } =
  commonSlicer.actions;
