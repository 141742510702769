import React from 'react'

//css
import './CentiBlickHomePage.scss'
import StickyHeader from './StickyHeader'
import images from '../../../Assets/Images'
import { duration } from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faHeart } from '@fortawesome/free-solid-svg-icons'
import Footer from './Footer/Footer'

const CentiBlickHomePage = () => {

    const Features = [
        { name: 'Compliance First', details: 'CentiBlick is designed to fully comply with HIPAA and HHS guidelines' },
        { name: 'Enhanced Security', details: 'Secure your telehealth sessions with end-to-end encryption, patient logins, & provider authentication' },
        { name: 'Seamless Integration', details: 'Easily integrate CentiBlick into your existing workflows with minimal disruption' },
        { name: 'Risk Mitigation', details: 'Stay ahead of regulatory changes & avoid penalties with a platform built for today’s healthcare environment' }
    ]

    const complianceData = [
        {
            title: "Secure Encrypted Appointment Links",
            description:
                "Other platforms’ open links sent via text or emails without login requirements pose significant security risk. These links can be intercepted or accessed by unauthorized individuals, leading to potential breaches of protected health information (PHI).",
            imageUrl: images.FirstCompliance,
            buttonText: "Compliance Requirement",
        },
        {
            title: "Access Control & Authentication",
            description:
                "HIPAA mandates the verification of individuals accessing PHI. Other platforms' open links without authentication do not meet this requirement, increasing the risk of unauthorized access.",
            imageUrl: images.SecondCompliance,
            buttonText: "Compliance Requirement",
        },
        {
            title: "Provider Registration & Identity Management",
            description:
                "As part of HIPAA compliance, it is essential to verify the identity and credentials of all providers using the telehealth platform. This ensures that only authorized and qualified individuals have access to sensitive patient information.",
            imageUrl: images.ThirdCompliance,
            buttonText: "Compliance Requirement",
        },
        {
            title: "End-to-End Encryption",
            description:
                "While other platforms’ telehealth sessions might be encrypted, the transmission of open links is not, exposing sensitive information to potential breaches and unauthorized patient and provider data access.",
            imageUrl: images.ForthCompliance,
            buttonText: "Compliance Requirement",
        },
    ];
    const pricingData = [
        {
            title: "Individual Provider",
            description:
                "Our individual provider platform offers a secure and encrypted solution for your telehealth needs. Enjoy identity protection, verified patient profiles, detailed encounter summaries, and a modern best-in-class experience for both you and your patients.",
            imageUrl: images.SecondCompliance,
            buttonText: "Get Compliance Now!",
            use: 'Unlimited use',
            price: "$35",
            duration: '/month',
            url: 'https://centiblickconnect.com/pricing'
        },
        {
            title: "Clinic Platform",
            description:
                "Our Clinic Platform includes all individual provider features plus a state-of-the-art admin center. Enjoy provider room directories, detailed patient and provider profiles, encounter summaries, and comprehensive platform analytics for a streamlined, modern experience.",
            imageUrl: images.ThirdCompliance,
            buttonText: "Get Compliance Now!",
            use: 'Unlimited use',
            price: "$50",
            duration: '/provider/month',
            url: 'https://centiblickconnect.com/pricing'
        },
        {
            title: "Add-On Features",
            description:
                "Start with the core features and expand with add-on functionalities as your needs grow. Access HIPAA Compliant Payments, 2-way EHR Integration, Flexcare virtual + location scheduling, patient appointments, 360 Medical Records, AI charting and so much more.",
            imageUrl: images.ForthCompliance,
            buttonText: "Contact Us",
            price: "Quoted",
            url: 'https://www.centiblick.com/contact-us'
        },
    ];

    const securityData = [
        { title: 'HIPPA Compliant' },
        { title: 'GDPR Compliant' },
        { title: 'HiTrust Compliant' },
        { title: 'SOC2 Certified' },
        { title: 'FedRAMP Certified' },
        { title: 'End-to-end Encryption' },
    ]

    const resourceData = [
        {
            image: images.SecondCompliance,
            avatar: images.videoCallImg,
            name: 'Danny Pettingill',
            date: 'Sep 16',
            waitingTime: '3 min',
            subTitle: 'Telehealth News & Information',
            title: 'Legacy Telehealth Platform Compliance Issues & What You Can...',
            description: 'Healthcare Providers are responsible for ensuring that...',
            views: '54'
        },
        {
            image: images.SecondCompliance,
            avatar: images.videoCallImg,
            name: 'Danny Pettingill',
            date: 'Jun 13',
            waitingTime: '4 min',
            title: 'What is Telehealth? An In-Depth Explainer',
            description: 'Telehealth refers to the use of digital communication technologies to deliver...',
            views: '13'
        },
        {
            image: images.SecondCompliance,
            avatar: images.videoCallImg,
            name: 'Danny Pettingill',
            date: 'Jun 5',
            waitingTime: '4 min',
            title: 'Meeting Patient Preferences: How CentiBlick Aligns with...',
            description: 'The healthcare landscape rapidly evolving, and modern telehealth has become a...',
            views: '7'
        },
    ]

    return (
        <div className='centiblick-home-page'>
            <div className='centiblick-header'>
                <StickyHeader />
            </div>
            <div className='hipaa-compliant container position-relative d-flex gap-4 mt-4'>
                <img src={images.PricingBg} alt="" className='first-bg' />
                <img src={images.PricingBg} alt="" className='second-bg' />
                <img src={images.PricingBg} alt="" className='third-bg' />
                <img src={images.PricingBg} alt="" className='forth-bg' />
                <img src={images.PricingBg} alt="" className='fifth-bg' />
                <img src={images.PricingBg} alt="" className='sixth-bg' />
                <div className='z-1'>
                    <h1 className='my-4'>
                        HIPAA & HHS Compliant
                    </h1>
                    <h2>
                        Telehealth Platform
                    </h2>
                    <p className='my-4'>
                        CentiBlick provides a secure, compliant telehealth platform that integrates seamlessly into your practice, reducing legal risks while enhancing patient care
                    </p>
                    <button className='rounded-save-theme-btn mt-3'>Get Compliant Now!</button>
                </div>
                <div className='z-1 w-100'>
                    <img src={images.providerJana} alt="" className='provider-img' />
                    <p className='mt-3 text-center' style={{ color: "#516cd3" }}>Telehealth powered by CentiBlick</p>
                </div>
            </div>
            <div className='container centiblick-features'>
                <div className='row'>
                    {
                        Features?.map((data: any) => (
                            <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12 d-flex mt-4'>
                                <div className='feature-details'>
                                    <h1 className='mb-3'>{data.name}</h1>
                                    <p>{data.details}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="compliance-container container">
                {complianceData.map((item, index) => (
                    <div className="compliance-item w-100" key={index}>
                        <img src={item.imageUrl} alt={item.title} className="compliance-image" />
                        <div className='d-flex flex-column justify-content-evenly align-items-start'>
                            <div>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </div>
                            <button className='save-theme-btn' onClick={() => window.open('https://www.centiblick.com/legacy-telehealth-platforms-compliance-issues-what-you-can-do', '_blank')}>{item.buttonText}</button>
                        </div>
                    </div>
                ))}
            </div>
            <div className='pricing-container'>
                <h1 className='text-center'>We Make Compliance Easy: <br /> Just Swap The Link!</h1>
                <div className='pricing-div container mt-5'>
                    {pricingData.map((item, index) => (
                        <div className="pricing-item w-100" key={index}>
                            <img src={item.imageUrl} alt={item.title} className="pricing-image" />
                            <div className='d-flex flex-column justify-content-evenly align-items-center'>
                                <div>
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center w-100 pricing-button'>
                                    <h2 className='price'>{item.price} <span>{item.duration}</span></h2>
                                    <button className='save-theme-btn' onClick={() => window.open(item.url, '_blank')}>{item.buttonText}</button>
                                </div>
                                <p className='useage'>{item.use}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='security-container container'>
                <div className='security-div w-50'>
                    <h1 className='text-center'>Enterprise Security</h1>
                    <div className='security-type align-items-center mt-5'>
                        {
                            securityData.map((item, index) => (
                                <div className='d-flex justify-content-between align-items-center security-details position-relative w-75'
                                    onClick={() => window.open('https://www.centiblick.com/security-trust', '_blank')}>
                                    <h1>{item.title}</h1>
                                    <img src={images.SecurityCheck} alt="" className='position-absolute ' />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* <div className='resource-container'>
                <div className='resource-div container'>
                    <h1>News, Resources, and Updates</h1>
                    <div className='row'>
                        {
                            resourceData?.map((data: any) => (
                                <div className='col-lg-4 col-md-4 col-sm-4 col-xs-12 d-flex mt-4'>
                                    <div className='resource-main-div'>
                                        <div className='resource-details h-100'>
                                            <img src={data.image} alt="" />
                                            <div className='resource-desc'>
                                                <div className='h-100'>
                                                    <div className='resource-user d-flex align-items-center gap-3'>
                                                        <img src={data.avatar} alt="" />
                                                        <div>
                                                            <p>{data.name}</p>
                                                            <p>{data.date} . {data.waitingTime}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p>{data.subTitle}</p>
                                                        <h2>{data.title}</h2>
                                                        <h3>{data.description}</h3>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='resource-view d-flex justify-content-between align-items-center'>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <FontAwesomeIcon icon={faEye} />
                                                        <p>{data.views}</p>
                                                    </div>
                                                    <FontAwesomeIcon icon={faHeart} color='#ff0000' />
                                                </div>
                                            </div>
                                        </div>
                                            <button className='save-theme-btn'>Read More</button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div> */}

            <div className='resource-container'>

                <div className=" resource-div container">
                    <h1>News, Resources, and Updates</h1>
                    <div className="row justify-content-center">
                        {resourceData.map((item, index) => (
                            <div key={index} className="col-xl-4 col-lg-3 col-md-4 mt-4 resource-details">
                                <div className="card h-100 p-3">
                                    <div className="card-image">
                                        <img src={item.image} alt={item.title} className="img-fluid" />
                                    </div>
                                    <div className="card-body text-start">
                                        <div className="author-info d-flex align-items-center mb-3">
                                            <img className="avatar rounded-circle" src={item.avatar} alt={item.name} />
                                            <div className="author-details ms-2">
                                                <p className="mb-0">{item.name}</p>
                                                <p className="text-muted">{item.date} • {item.waitingTime}</p>
                                            </div>
                                        </div>
                                        <p className="subTitle text-muted">{item.subTitle}</p>
                                        <h5 className="title">{item.title}</h5>
                                        <p className="description text-muted">{item.description}</p>
                                    </div>
                                    <hr className='my-0 mx-3'/>
                                    <div className="card-footer bg-transparent d-flex justify-content-between align-items-center">
                                        <div className="views d-flex align-items-center">
                                            <span className="icon">👁</span>
                                            <span className="ms-2">{item.views}</span>
                                        </div>
                                        <button className="like-button border-0 bg-transparent">❤️</button>
                                    </div>
                                    <button className="save-theme-btn mt-3" onClick={()=> window.open('https://www.centiblick.com/resources','_blank')}>Read More</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default CentiBlickHomePage