import { faApple } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import images from '../../../Assets/Images';

import './LoginScreen.scss'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAdminplan } from '../../../Store/Slicers/commonSlicer';
import { EyeClose, EyeOpen, LandingLogo, LoginPopupBG, Padlock } from '../../../Assets/Svgs';
import AdminFormFlow from './AdminFormFlow';
import { Amplify, Auth } from 'aws-amplify';
import { fetchAdminProfileData } from '../../../Store/Thunk/getProfileData/adminProfileThunk';
import { setLocalStorage } from '../../../Components/Admin/Utils/AuthHandlers';
import { toast } from 'react-toastify';
import { passwordValidation } from '../../../Components/utils';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { AdminAwsConfig } from '../../../awsConfig';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ResetPasswordProfile } from '../../../Store/Thunk/ResetPassword/resetPasswordThunk';
import { ToastMessage } from '../../../Components/Common/ToastMessage';

Amplify.configure({
    Auth: {
        region: AdminAwsConfig.REGION,
        userPoolId: AdminAwsConfig.USER_POOL_ID,
        userPoolWebClientId: AdminAwsConfig.USER_POOL_CLIENT_ID,
    },
});

const AdminLoginScreen = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showModal, setShowModal] = useState<boolean>(true);
    const [isRegisterClick, setIsRegisterClick] = useState<boolean>(false);
    const [isContinueClick, setIsContinueClick] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);

    const [regiterEmail, setRegiterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [userName, setUserName] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [isSendVerification, setIsSendVerification] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [forgotModal, setForgotModal] = useState<boolean>(false);


    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (e: any) => {
        const newPassword = e.target.value;
        setRegisterPassword(newPassword);
        validatePasswords(e.target.value, registerPasswordConfirm);
        const validationError: any = passwordValidation(newPassword);
        setPasswordError(validationError);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const validatePasswords = (password: any, confirmPassword: any) => {
        setPasswordsMatch(password === confirmPassword);
    };

    const handleConfirmPasswordChange = (e: any) => {
        setRegisterPasswordConfirm(e.target.value);
        validatePasswords(registerPassword, e.target.value);
    };

    const handleSubmit = async (values: any) => {
        toast.loading("Please Wait")
        try {
            await Auth.signIn(values.email, values.password, {
                profile: 'admin'
            }).then((res: any) => {
                toast.dismiss()
                let authToken = res?.signInUserSession?.idToken?.jwtToken;
                let userId = res.signInUserSession?.accessToken?.payload?.sub;
                if (authToken) {
                    dispatch(fetchAdminProfileData({ userId, authToken }))
                    setLocalStorage("userToken", authToken);
                    setLocalStorage('userData', JSON.stringify(res.signInUserSession.accessToken.payload))
                    setLocalStorage('userEmail', res.attributes.email)
                    toast.dismiss()
                    dispatch(setAdminplan("clinic"))
                    navigate("/admin/homelisting");
                    setEmail('')
                    setPassword('')
                }
            }).catch((error) => {
                toast.dismiss()
                ToastMessage("error","Something went wrong.Please try again later.")
            })
        } catch (error: any) {
            console.log('error', error)
        }
    }

    const handleResendCode = async (email: string) => {
        toast.loading('Resending code...');
        try {
            await Auth.resendSignUp(email);
            toast.dismiss();
            ToastMessage("success",'Verification code sent successfully!');
        } catch (error) {
            toast.dismiss();
            ToastMessage("error",'Failed to resend verification code. Please try again.');
        }
    };

    const handleRegister = () => {
        setIsRegisterClick(true)
    }

    const handleFormSubmit = async (e: any) => {
        toast.loading("Please wait...")
        e.preventDefault()
        try {
            await Auth.signUp({
                username: regiterEmail,
                password: registerPassword,
                attributes: {
                    name: userName,
                    email: regiterEmail,
                    profile: 'admin'
                },
            })
            toast.dismiss();
            setIsSendVerification(true)
        } catch (error: any) {
            console.error("Error signing up:", error);
            toast.dismiss();
            ToastMessage("error","Something went wrong")
        }
    };

    const handleVerification = async (e: any) => {
        toast.loading("Please wait...")
        e.preventDefault()
        try {
            await Auth.confirmSignUp(regiterEmail, verificationCode);
            await Auth.signIn(regiterEmail, registerPassword, {
                type: 'admin'
            });

            const userData = await Auth.currentAuthenticatedUser();
            if (userData) {
                setLocalStorage("SignUpUserName", userName);
                setLocalStorage("userToken", userData.signInUserSession.idToken.jwtToken);
                setLocalStorage('userData', JSON.stringify(userData.signInUserSession.accessToken.payload))
                setLocalStorage('userEmail', userData.attributes.email)
            }
            toast.dismiss()
            setIsSendVerification(false)
            setIsContinueClick(true);
        } catch (error: any) {
            toast.dismiss()
            ToastMessage("error","Something went wrong")
            console.log("🚀 ~ handleVerification ~ error:", error)
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent, submitFunction: () => void) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submission
            submitFunction(); // Call the submit function
        }
    };

    const handleForgot = () => {
        setForgotModal(true);
    };

    const handlePassword = async (email: string) => {
        setLoader(true);
        toast.loading("Please wait...");
        let response: any = await dispatch(ResetPasswordProfile({ email }));
        if (response?.payload?.message === "password reset successful.") {
            ToastMessage("success","Email sent successfully");
        } else {
            ToastMessage("error","Something went wrong. Please try again later.");
        }
    };


    return (
        <div>
            <div className="background-container" >
                <img src={LoginPopupBG} alt="" />
            </div>
            <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
            >
                <div
                    className={`modal-dialog modal-dialog-scrollable modal-dialog-centered justify-content-center ${isContinueClick ? "modal-xl" : "modal-lg"}`}
                >
                    <div className="modal-content p-0 bg-transparent shadow-none border-0 overflow-visible" style={{ maxWidth: isContinueClick ? "" : "628px" }}>
                        <div className="modal-body p-0">
                            <div className="waiting-screen new-login-popup">
                                {
                                    forgotModal ? (
                                        <div className="waiting-login">
                                            <h1 className="waiting-header justify-content-center align-items-center flex-row gap-4">Let’s setup a new password! <img src={Padlock} alt="" /></h1>
                                            {/* <p className="desc">Let’s setup a new password</p> */}
                                            <div className="signin-div d-flex w-75 align-items-center flex-column gap-4">
                                                <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                    <Formik
                                                        initialValues={{ email: '' }}
                                                        validationSchema={Yup.object({
                                                            email: Yup.string().email('Invalid email address').required('Email Required'),
                                                        })}
                                                        onSubmit={({ email }) => handlePassword(email)}
                                                    >
                                                        {({ handleSubmit, errors, touched }) => (
                                                            <Form className="login-form d-flex flex-column" onSubmit={handleSubmit}>
                                                                <div>
                                                                    <Field
                                                                        name="email"
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter your Email"
                                                                        autoComplete='new-email'
                                                                        onKeyDown={(e: any) => handleKeyDown(e, handleSubmit)}
                                                                    />
                                                                    <ErrorMessage component='div' name='email' className='text-danger' />
                                                                </div>
                                                                <button
                                                                    type="submit"
                                                                    className="yes-theme-btn border-0"
                                                                    style={{
                                                                        padding: "16px 24px",
                                                                        borderRadius: "6px",
                                                                    }}
                                                                    disabled={loader}
                                                                >
                                                                    Continue
                                                                </button>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                                <div className={`text-center login-logo`}>
                                                    <img src={LandingLogo} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                        !isRegisterClick ?

                                            <div className="waiting-login">
                                                <h1 className="waiting-header">
                                                    Welcome!
                                                </h1>
                                                <p className="desc">
                                                    Please sign in or register as an Admin to access your Admin tool.
                                                </p>
                                                <div className="signin-div w-100 d-flex align-items-center flex-column gap-4">
                                                    <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                        <h1 className="signin-header">Sign In</h1>
                                                        <Formik
                                                            initialValues={{ email: '', password: '' }}
                                                            validationSchema={Yup.object({
                                                                email: Yup.string().email('Invalid email address').required('Eamil is Required'),
                                                                password: Yup.string().required('Password is Required'),
                                                            })}
                                                            onSubmit={handleSubmit}
                                                        >
                                                            {({ handleSubmit, errors, touched }) => (
                                                                <Form className="login-form d-flex flex-column">
                                                                    <div>
                                                                        <Field
                                                                            name="email"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter your Email Address"
                                                                            autoComplete='new-email'
                                                                            onKeyDown={(e: any) => handleKeyDown(e, handleSubmit)}
                                                                        />
                                                                        <ErrorMessage component='div' name='email' className='text-danger' />
                                                                    </div>
                                                                    <div>
                                                                        <div className='position-relative'>
                                                                            <Field
                                                                                name="password"
                                                                                type={showPassword ? "text" : "password"}
                                                                                className="form-control"
                                                                                placeholder="Password"
                                                                                autoComplete='new-password'
                                                                                onKeyDown={(e: any) => handleKeyDown(e, handleSubmit)}
                                                                            />
                                                                            <img
                                                                                src={showPassword ? EyeOpen : EyeClose}
                                                                                className="position-absolute cursor-pointer"
                                                                                style={{ right: "15px", bottom: "20px" }}
                                                                                onClick={handleTogglePasswordVisibility}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <ErrorMessage component='div' name='password' className='text-danger' />
                                                                    </div>
                                                                    <div className="remember d-flex align-items-center">
                                                                        <input type="checkbox" />
                                                                        <span>Remember me on this computer</span>
                                                                    </div>
                                                                    <button
                                                                        type="submit"
                                                                        className="yes-theme-btn border-0"
                                                                        style={{
                                                                            padding: "16px 24px",
                                                                            borderRadius: "6px",
                                                                        }}
                                                                    >
                                                                        Sign In
                                                                    </button>
                                                                    <p onClick={handleForgot} className="forgotpassword-title">Forgot Password</p>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </div>
                                                    <div className='signUp-tag'>
                                                        Don’t have a Telehealth account <span onClick={() => navigate('/pricing')}>Signup</span> today
                                                    </div>
                                                    {/* <div
                                                    className={`vertical-line `}
                                                ></div>
                                                <div
                                                    className={`social-login w-100 d-flex flex-column`}
                                                >
                                                    <div className="register d-flex flex-column">
                                                        <h1 className="signin-header">
                                                            Don’t have a Profile?
                                                        </h1>
                                                        <button
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                            onClick={() => handleRegister()}
                                                        >
                                                            Register
                                                        </button>
                                                    </div>
                                                    <div className="social-account">
                                                        <div className="google d-flex align-items-center mb-1">
                                                            <img src={images.Google} alt="Google" />
                                                            Continue with Google
                                                        </div>
                                                        <div className="apple d-flex align-items-center">
                                                            <FontAwesomeIcon icon={faApple} color="#ffffff" />
                                                            Continue with Apple
                                                        </div>
                                                    </div>
                                                </div> */}
                                                    <div className={`text-center login-logo`}>
                                                        <img src={LandingLogo} alt="" />
                                                    </div>
                                                </div>
                                            </div> :
                                            isContinueClick ?
                                                <AdminFormFlow /> :
                                                <div className="waiting-login align-items-center">
                                                    <h1 className="waiting-header d-flex justify-content-center align-items-center gap-4">
                                                        Choose username and password!
                                                        <img src={Padlock} alt="" />
                                                    </h1>
                                                    {/* <p className='desc'>Let’s setup a user name and password to keep your information and Telehealth session safe</p> */}
                                                    <form
                                                        className="register-form d-flex flex-column w-75"
                                                        onSubmit={isSendVerification ? handleVerification : handleFormSubmit}
                                                    >
                                                        {isSendVerification ?
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter verification code"
                                                                    value={verificationCode}
                                                                    onChange={(e) => setVerificationCode(e.target.value)}
                                                                />
                                                                <div>
                                                                    <button type="button" className="save-theme-btn d-flex align-items-center gap-2" style={{ padding: "8px 12px" }} onClick={() => handleResendCode(regiterEmail)}>
                                                                        <FontAwesomeIcon icon={faArrowRotateRight} /> Resend Code
                                                                    </button>
                                                                </div>
                                                            </> : <>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Email"
                                                                    value={regiterEmail}
                                                                    onChange={(e) => setRegiterEmail(e.target.value)}
                                                                    autoComplete="new-email"
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="User Name"
                                                                    value={userName}
                                                                    onChange={(e) => setUserName(e.target.value)}
                                                                    autoComplete="new-user"
                                                                />
                                                                <div>
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type={showPassword ? "text" : "password"}
                                                                            className="form-control"
                                                                            placeholder="Password"
                                                                            value={registerPassword}
                                                                            onChange={handlePasswordChange}
                                                                            autoComplete="new-password"
                                                                        />
                                                                        <img
                                                                            src={showPassword ? EyeOpen : EyeClose}
                                                                            className="position-absolute cursor-pointer"
                                                                            style={{ right: "15px", bottom: "20px" }}
                                                                            onClick={handleTogglePasswordVisibility}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    {
                                                                        registerPassword === "" && <div className="text-danger">Password is required</div>
                                                                    }
                                                                    {passwordError && (
                                                                        <div className="text-danger">{passwordError}</div>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type={showConfirmPassword ? "text" : "password"}
                                                                            className="form-control"
                                                                            placeholder="Re-enter Password"
                                                                            value={registerPasswordConfirm}
                                                                            onChange={handleConfirmPasswordChange}
                                                                            autoComplete="new-password"
                                                                        />
                                                                        <img
                                                                            src={showConfirmPassword ? EyeOpen : EyeClose}
                                                                            className="position-absolute cursor-pointer"
                                                                            style={{ right: "15px", top: "22px" }}
                                                                            onClick={handleToggleConfirmPasswordVisibility}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    {!passwordsMatch && (
                                                                        <div className="text-danger">Passwords do not match</div>
                                                                    )}
                                                                </div>
                                                                <div className="remember d-flex align-items-center">
                                                                    <input type="checkbox" />
                                                                    <span>Remember me on this computer</span>
                                                                </div>
                                                            </>}
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{ padding: "16px 24px", borderRadius: "6px" }}
                                                            disabled={passwordError !== '' || !passwordsMatch || registerPassword === ""}
                                                        >
                                                            Continue
                                                        </button>
                                                        {(isRegisterClick && !isSendVerification) && <p onClick={() => setIsRegisterClick(false)} className="login-text">If already have an account <span>Log In</span></p>}
                                                    </form>
                                                </div>
                                }
                                {/* <div className={`text-center login-logo mt-4`}>
                                    <img src={LandingLogo} alt="" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AdminLoginScreen