import React, { useEffect, useRef, useState } from 'react'
import ImageHandler from '../../../Components/Common/ImageHandler'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store';
import images from '../../../Assets/Images';
import { capitalizeFirstLetter, getInitials, lightenColor } from '../../../Components/utils';
import { reset, setIsUserSignIn, setPlan } from '../../../Store/Slicers/commonSlicer';
import routeHandlers from '../../../Components/routeHandler';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getLocalStorage, logoutHandler } from '../../../Components/Patient/Utils/AuthHandlers';
import { useWebSocket } from '../../../SocketConnection/WebSocketContext';
import { fetchPatientProfileData } from '../../../Store/Thunk/getProfileData/patientProfileThunks';
import CompleteProfileModal from '../../../Components/Common/Modals/CompleteProfileModal/CompleteProfileModal';
import { Amplify } from 'aws-amplify';
import { PatientAwsConfig } from '../../../awsConfig';
import moment from 'moment';
import PermissionFlowModal from './PermissionFlowModal';
import { Gear, Ring1, Ring2, Ring3 } from '../../../Assets/Svgs';
import { fetchIndividualProvider } from '../../../Store/Thunk/Onboarding/getIndividualProvider';
import { fetchClinicalProvider } from '../../../Store/Thunk/Onboarding/getClinicalProvider';
import { setheaderTextvalue, setSelectedPrimaryColor, setSelecteSecondaryColor, setTextColor } from '../../../Store/Slicers/OnBoarding/OnBoardingSlicer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import NewForgotPassword from '../../../Components/Common/NewForgotPassword';
import Loader from '../../../Components/Common/Loader/Loader';

const CommonWaiting = ({ componentName }: any) => {

    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const UserId = getLocalStorage("UserId");
    const authToken = getLocalStorage('userToken')

    const dropdownRef = useRef<any>(null);


    const userDataPatient = getLocalStorage("userData");

    const { themeColor, plan, slider, fullScreen, startVideoCall, room_id } = useSelector((state: RootState) => state.app);
    const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors);

    const { headerText, textColor } = useSelector((state: RootState) => state.onBoarding.serviceValues);
    const PatientUserData = useSelector((state: RootState) => state.patientProfile.PatientUserData)?.[0]
    const patientProfileModal = useSelector((state: RootState) => state.patientProfile.PatientProfileModal);

    const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
    const [permissionModal, setPermissionModal] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [forgotModal, setForgotModal] = useState<boolean>(false)
    const [socket, setSocket] = useState<any>(null)
    const [loader, setLoader] = useState<boolean>(true)

    const { disconnectWebSocket } = useWebSocket();


    const style = document.createElement('style');
    style.type = 'text/css';

    // Define CSS rules including hover effect
    const cssRules = `
    .save-theme-btn {
      border-color: ${selectedPrimaryColor};
      border-color: ${selectedPrimaryColor};
    }
    
    .save-theme-btn:hover {
      background-color: ${selectedPrimaryColor}; 
      color:#fff;
    }
    .rounded-save-theme-btn {
      border-color: ${selectedPrimaryColor};
      background-color: ${selectedPrimaryColor};
    }
    
    .rounded-save-theme-btn:hover {
      background-color: ${selectedPrimaryColor}; 
    }
    .yes-theme-btn,
    .active-session {
      border-color: ${selectedPrimaryColor};
      background-color: ${selectedPrimaryColor} !important;
      color:#fff !important;
    }
    
    .yes-theme-btn:hover,
    .active-session:hover {
      background-color: ${selectedPrimaryColor}; 
    }
    .form-control, .cb-select, .provider-search-box, .select__control {
      border-color: ${lightenColor(selectedPrimaryColor, 0.5)} !important;
    }
    .signatureCanvas {
      border: 1px solid ${lightenColor(selectedPrimaryColor, 0.5)} !important;
    }
    
    .form-control:hover,
    .cb-select:hover,
    .provider-search-box:hover,
    .select__control:hover,
    .signatureCanvas:hover{
     border-color: ${selectedPrimaryColor} !important;
    }
  
     [class*='para-circle-avatar'] {
      border-color: ${selectedPrimaryColor} !important;
      background-color: ${lightenColor(selectedPrimaryColor, 0.8)} !important;
      color: ${selectedPrimaryColor} !important;
    }
  
    .MuiOutlinedInput-notchedOutline{
    border-color: ${selectedPrimaryColor} !important;
    }
    .setting-content{
    border-color: ${selectedPrimaryColor} !important;
    }
    .sidebar-icon-box svg{
     width : 96px; !important;
     height : 96px; !important;
    }
    .meeting-div {
     background-color : #E2EAED80 !important;
     }
     .meeting-profile, .chat-div, .chat-header, .modal-logo {
        border-color : ${selectedPrimaryColor} !important;
        }
        
    .chat-header{
        background-color : ${selectedPrimaryColor} !important;
     }
    .user-message{
        background-color : ${selectedPrimaryColor} !important;
     }
    .bot-message{
        background-color : ${selectedSecondaryColor} !important;
     }
    .permission-modal{
        background-color : ${lightenColor(selectedPrimaryColor, 0.8)} !important;
     }
    .send-msg div{
        background-color : ${lightenColor(selectedPrimaryColor, 0.5)} !important;
     }
    .get-msg div{
        background-color : ${selectedSecondaryColor} !important;
     }
  `;

    // Add the CSS rules to the <style> element
    style.appendChild(document.createTextNode(cssRules));

    // Append the <style> element to the <head> of the document
    document.head.appendChild(style);


    const ComponentToRender = routeHandlers[componentName]["component"];
    const prevLink = routeHandlers[componentName]["prevLink"];

    Amplify.configure({
        Auth: {
            region: PatientAwsConfig.REGION,
            userPoolId: PatientAwsConfig.USER_POOL_ID,
            userPoolWebClientId: PatientAwsConfig.USER_POOL_CLIENT_ID,
        },
    });


    const handleSet = (value: string) => {
        if (value === "enterprise") {
            navigate('/home')
        }
        dispatch(setPlan(value));
    }

    const redirectUrl = sessionStorage.getItem('redirect_url')

    useEffect(() => {
        const ws = new WebSocket(
            `wss://8uhsg8qi47.execute-api.us-east-1.amazonaws.com/production?userId=${userDataPatient.sub}&userType=patient`
        );
        setSocket(ws)

        ws.onopen = () => {
            console.log("WebSocket connection established.");
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onclose = () => {
            console.log("WebSocket connection closed.");
        };
    }, [])

    const handleLogout = () => {

        const message = {
            action: "waitingRoom",
            from_user_id: userDataPatient.sub,
            to_user_id: room_id,
            channel: "VIDEO",
            status: "REJECTED",
            initiated_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss"),
        };
        socket.send(JSON.stringify(message));
        socket.close();
        disconnectWebSocket();
        logoutHandler(plan, redirectUrl);
        dispatch(setIsUserSignIn(false));
        dispatch(reset());
    };


    const handleClose = () => {
        setIsCompleteModalOpen(false)
    }

    const fetchOnboardingData = async () => {
        let response: any;
        if (hostname === 'provider.centiblickconnect.com' || hostname === 'localhost') {
            response = await dispatch(fetchIndividualProvider({ userId: userDataPatient.sub, authToken }))
        } else if (hostname === 'clinic.centiblickconnect.com') {
            response = await dispatch(fetchClinicalProvider({ userId: userDataPatient.sub, authToken }))

        }
        if (response?.payload.status === 200) {
            const data = response?.payload.data

            dispatch(setSelectedPrimaryColor(data.primary_color))
            dispatch(setSelecteSecondaryColor(data.secondary_color))
            dispatch(setTextColor(data.text_color_code))
            dispatch(setheaderTextvalue(data.service_name))

            const styleElement = document.createElement('style');

            const cssRule = `#onboarding-screen, #onboarding-screen * { color: ${data.primary_color}; ::-webkit-scrollbar-thumb { background: ${data.primary_color}; } }`;

            styleElement.appendChild(document.createTextNode(cssRule));
            document.head.appendChild(styleElement);
            setLoader(false)

        } else {
            setLoader(false)
        }

        console.log("🚀 ~ fetchOnboardingData ~ respose:", response)
    }

    const fetchData = async () => {
        const response: any = await dispatch(fetchPatientProfileData({ userId: userDataPatient.sub ? userDataPatient.sub : UserId, authToken }))

        if (patientProfileModal && location.pathname !== "/patient-profile") {
            if (location.pathname === "/patient/meeting-screen") {
                setIsCompleteModalOpen(true)
            }
        }
    }
    const currunt_url = new URL(window.location.href)
    const hostname = currunt_url.hostname
    useEffect(() => {
        fetchData()
        fetchOnboardingData()

        if (hostname === 'provider.centiblickconnect.com') {
            dispatch(setPlan('Individual'))
        } else if (hostname === 'clinic.centiblickconnect.com') {
            dispatch(setPlan('clinic'))
        } else {
            dispatch(setPlan('clinic'))
        }
    }, [])

    useEffect(() => {
        if (patientProfileModal && location.pathname !== "/patient-profile") {
            if (location.pathname === "/patient/meeting-screen") {
                setTimeout(() => {
                    setIsCompleteModalOpen(true)
                }, 100);
            }
        }
    }, [isCompleteModalOpen, location.pathname, patientProfileModal])

    const handlePermission = () => {
        setPermissionModal(true)
    }

    const handleClosePermissionModal = () => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(stream => {
                stream.getTracks().forEach(track => track.stop());
            })
            .catch(error => {
                console.error("Error accessing media devices.", error);
            });

        setPermissionModal(false)
    }

    const handleGear = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }


    const handleOutsideClick = (event: MouseEvent) => {
        if (
            (dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node))
        ) {
            setIsDropdownOpen(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleForgotPassword = () => {
        setForgotModal(true)
    }

    return (
        <>
            {loader ? <Loader /> :
                <>
                    {
                        forgotModal ?
                            <NewForgotPassword />
                            :
                            <div className="main-page-wrapper" id="onboarding-screen" style={{ height: fullScreen ? "100vh" : window.innerWidth <= 767 && startVideoCall ? "calc(100vh - 50px)" : "" }}>
                                <img src={Ring1} alt="" className='bg-ring-1 position-absolute' />
                                <img src={Ring2} alt="" className='bg-ring-2 position-absolute' />
                                <img src={Ring3} alt="" className='bg-ring-3 position-absolute' />
                                <div className="header" style={{ display: fullScreen ? "none" : "block", background: selectedSecondaryColor ? selectedSecondaryColor : "" }}>
                                    <nav className="navbar" style={{ background: selectedSecondaryColor ? selectedSecondaryColor : "" }}>
                                        <div className="container-fluid">
                                            <button
                                                className="navbar-toggler d-md-none"
                                                type="button"
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasNavbar"
                                                aria-controls="offcanvasNavbar"
                                            >
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                            <div className="custom-navbar">
                                                <div
                                                    className="nav-left d-md-flex cursor-pointer"
                                                    style={{ display: "none" }}
                                                    onClick={() => navigate('/')}
                                                >
                                                    <h1 style={textColor ? { color: textColor } : {}}>{headerText !== 'Service Name' ? headerText : "Troy Medical"}</h1>
                                                    <img src={images.authPower} alt="power" className="power-img" />
                                                </div>
                                                <div className="nav-right">
                                                    <div className='position-relative gear-icon-div d-flex' ref={dropdownRef}>
                                                        <img src={Gear} about="" alt="setting" className='cursor-pointer' onClick={handleGear} />
                                                        {isDropdownOpen && (
                                                            <div className='setting-content d-flex flex-column'>
                                                                <div onClick={handleForgotPassword}>
                                                                    <p>Reset Password</p>
                                                                </div>
                                                                <div onClick={() => navigate('/support')}>
                                                                    <p>Contact Support</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <div className="dropdown dropdown-patient">
                                    <button className={`btn`} type="button" id="dropdownMenuButton1" data-bs-toggle={``} aria-expanded="false">
                                        {capitalizeFirstLetter(plan)}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        {
                                            plan != "Individual" &&
                                            <li><a className="dropdown-item" href="#" onClick={() => handleSet('Individual')}>Individual</a></li>
                                        }
                                        {plan != "enterprise" &&
                                            <li><a className="dropdown-item" href="#" onClick={() => handleSet('enterprise')}>Enterprise</a></li>
                                        }
                                        {plan != "clinic" &&
                                            <li><a className="dropdown-item" href="#" onClick={() => handleSet('clinic')}>Clinic</a></li>
                                        }
                                    </ul>
                                </div> */}
                                                    {/* <div className="language-select">
                                    <ImageHandler
                                        theme={themeColor}
                                        imageName="languageImage"
                                        className=""
                                    />
                                    <select
                                        defaultValue={"en"}
                                        className="form-select"
                                        aria-label="Default select example"
                                    //   onChange={(e) => langaugeHandler(e)}
                                    >
                                        <option value="en">Eng</option>
                                        <option value="fr">Fre</option>
                                    </select>
                                </div> */}
                                                    <button
                                                        className="btn logout-btn"
                                                        onClick={handleLogout}
                                                        style={{ border: selectedPrimaryColor ? `1px solid ${selectedPrimaryColor}` : "" }}
                                                    >
                                                        Logout
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                className="offcanvas offcanvas-start d-lg-none"
                                                id="offcanvasNavbar"
                                                aria-labelledby="offcanvasNavbarLabel"
                                            >
                                                <div className="offcanvas-header">
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="offcanvas"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div className={`sidebar-wrapper sidebar-toggle ${slider ? 'open-sidebar' : 'closed-sidebar'}`} style={{ height: fullScreen ? "100vh" : "", background: startVideoCall ? "transparent" : selectedPrimaryColor ? lightenColor(selectedPrimaryColor, 0.8) : "" }}>
                                                    <div
                                                        className="sidebar-user patient-sidebar-user d-md-flex justify-content-between"
                                                        style={{ backgroundColor: "#ffffff", height: "100%" }}
                                                    >
                                                        <div className='text-center d-flex flex-column gap-3'>
                                                            {
                                                                PatientUserData && PatientUserData.profile_image_path ?
                                                                    <img src={process.env.REACT_APP_AWS_S3_BUCKET_PATH + "/" + PatientUserData?.profile_image_path} className="shadow-none blue_adminHomePamel cursor-pointer" width={170} alt='profile-img' onClick={() => navigate('/patient/meeting-screen')} />
                                                                    :
                                                                    <div className="cursor-pointer para-img-div-profile d-flex justify-content-center" onClick={() => navigate('/patient/meeting-screen')}>
                                                                        <div className="para-circle-avatar-profile m-0">
                                                                            <p className="para-image-profile">{getInitials(PatientUserData?.first_name ? PatientUserData.first_name + " " + PatientUserData.last_name : "Patient 001")}</p>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            <h2>
                                                                {PatientUserData?.first_name ? PatientUserData.first_name + " " + PatientUserData.last_name : "Patient 001"}
                                                            </h2>
                                                            <button
                                                                className="yes-theme-btn"
                                                                onClick={() => navigate("/patient-profile")}
                                                            >
                                                                my Profile
                                                            </button>
                                                        </div>
                                                        <button className='save-theme-btn' style={{ textShadow: "none", boxShadow: " 0px 8px 4px 0px #536DCE26,-3px -4px 7px 0px #FFFFFF26 inset" }} onClick={handlePermission}>
                                                            Pre-call Test
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <div className={`d-flex ${window.innerWidth <= 767 && 'position-relative'}`} style={{ height: fullScreen ? "100vh" : "calc(100vh - 60px)" }}>
                                    <div className={`sidebar-wrapper sidebar-toggle z-1 ${slider ? 'open-sidebar' : 'closed-sidebar'}`} style={{ height: fullScreen ? "100vh" : "", background: startVideoCall ? "transparent" : selectedSecondaryColor ? selectedSecondaryColor : "", display: window.innerWidth > 767 ? "block" : "none" }}>
                                        <div
                                            className="sidebar-user patient-sidebar-user d-md-flex justify-content-between"
                                            style={{ backgroundColor: "#ffffff", height: "100%" }}
                                        >
                                            <div className='text-center d-flex flex-column gap-3'>
                                                {
                                                    PatientUserData && PatientUserData.profile_image_path ?
                                                        <img src={process.env.REACT_APP_AWS_S3_BUCKET_PATH + "/" + PatientUserData?.profile_image_path} className="shadow-none blue_adminHomePamel cursor-pointer" width={170} alt='profile-img' onClick={() => navigate('/patient/meeting-screen')} />
                                                        :
                                                        <div className="cursor-pointer para-img-div-profile d-flex justify-content-center" onClick={() => navigate('/patient/meeting-screen')}>
                                                            <div className="para-circle-avatar-profile m-0">
                                                                <p className="para-image-profile">{getInitials(PatientUserData?.first_name ? PatientUserData.first_name + " " + PatientUserData.last_name : "Patient 001")}</p>
                                                            </div>
                                                        </div>
                                                }
                                                <h2>
                                                    {PatientUserData?.first_name ? PatientUserData.first_name + " " + PatientUserData.last_name : "Patient 001"}
                                                </h2>
                                                <button
                                                    className="yes-theme-btn"
                                                    onClick={() => navigate("/patient-profile")}
                                                >
                                                    my Profile
                                                </button>
                                            </div>
                                            <button className='save-theme-btn' style={{ textShadow: "none", boxShadow: " 0px 8px 4px 0px #536DCE26,-3px -4px 7px 0px #FFFFFF26 inset" }} onClick={handlePermission}>
                                                Pre-call Test
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`w-100 position-relative ${location.pathname === '/patient/meeting-screen' ? 'p-0' : !startVideoCall ? 'p-4' : 'p-0'}`}>
                                        {
                                            !startVideoCall &&
                                            <nav aria-label="breadcrumb" style={{ display: location.pathname === "/patient/meeting-screen" ? "none" : "" }}>
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item active cursor-pointer" aria-current="page" onClick={() => { prevLink && navigate(prevLink) }}>
                                                        {prevLink &&
                                                            <Link to={prevLink}>
                                                                <FontAwesomeIcon icon={faAngleLeft} color="#3B8FA9" />
                                                            </Link>
                                                        }
                                                        {routeHandlers[componentName]["componentTitle"]}
                                                    </li>
                                                </ol>
                                            </nav>
                                        }
                                        {/* {deviceWidth > 767 ? <ComponentToRender /> : null} */}
                                        <ComponentToRender />

                                    </div>
                                </div>
                                {
                                    isCompleteModalOpen && <CompleteProfileModal isProfileModalOpen={isCompleteModalOpen} handleClose={handleClose} navigateUrl="/patient-profile" />
                                }

                                {
                                    permissionModal && <PermissionFlowModal modalOpen={permissionModal} handleClose={handleClosePermissionModal} />
                                }
                            </div>
                    }
                </>
            }
        </>
    )
}

export default CommonWaiting